.x-space {
  padding-left: v(safe-left);
  padding-right: v(safe-right);
}

.grid {
  @media (--grid) {
    width: 100%;
    max-width: 132rem;
    margin-right: auto;
    margin-left: auto;
  }
}
