$global-w: (
  'phone': 20em, // 320px
  'tablet-7': 37.5em, // 600px
  'tablet-10': 52.5em, // 840px
  'grid': 80em, // 1280px (1160 + 2 * x-space)
);

@include --w($global-w);

/* Safe distances */

:root {
  --primary: #{$primary};
  --bg: #{$bg};
  --accent: #{$accent};

  --safe-top: var(--safe-area-top);
  --safe-right: var(--safe-area-right, 2rem);
  --safe-bottom: var(--safe-area-bottom);
  --safe-left: var(--safe-area-left, 2rem);

  @supports (top: #{'max(0px)'}) {
    --safe-area-top: env(safe-area-inset-top, 0);
    --safe-area-right: max(env(safe-area-inset-right), 2rem);
    --safe-area-bottom: env(safe-area-inset-bottom, 0);
    --safe-area-left: max(env(safe-area-inset-left), 2rem);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (--reduced-motion) {
    transition: 0.001s !important;
    animation: 0.001s !important;
  }
}

* {
  touch-action: manipulation;
}

::selection {
  background: v(accent);
  color: v(bg);
}

/* HTML & BODY */

html {
  width: 100%;
  height: 100%;

  font: 400 46.875% / #{$body-lh} $body-font;
  @include font-smoothing(on);

  background: v(bg);
  color: v(primary);

  @media (--phone) {
    font-size: 62.5%;
  }
}

body {
  width: 100%;
  min-height: 100%;

  font-size: $body-size;

  @media (--phone) {
    overflow-x: hidden;
  }
}

/* LISTS */

ul,
ol {
  margin: 0;
  padding-left: 0;

  list-style-type: none;
}

dl,
dd {
  margin: 0;
}

/* QUOTES */

blockquote {
  margin: 0;
}

/* IMAGES */

// figure {
//   margin: 0;

//   font-size: 0;
// }

// img {
//   position: relative;
//   // z-index: 1;
//   display: block;
//   width: 100%;
//   height: auto;

//   &::selection {
//     background: v(accent);
//   }

//   // // Not loading images.
//   // &::after {
//   //   content: '\0274f' ' ' attr(alt);
//   //   position: absolute;
//   //   top: 0;
//   //   left: 0;
//   //   display: flex;
//   //   justify-content: center;
//   //   align-items: center;
//   //   width: 100%;
//   //   height: 100%;
//   //   padding: 0 1em;

//   //   color: $jumbo;
//   //   background: lighten($jumbo, 21%);

//   //   font-size: $body-size;
//   // }
// }

/* FORMS */

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

input {
  padding: 0;

  appearance: none;
  border-radius: 0; // Safari iOS

  line-height: inherit;

  // Safari MacOS contact icon.
  &::-webkit-contacts-auto-fill-button {
    transform: translate3d(100%, 0, 0);
    display: block !important;

    background-color: v(accent);
    opacity: 0;
  }

  &:focus,
  &:hover {
    &::-webkit-contacts-auto-fill-button {
      transform: translate3d(0, 0, 0);
      display: block !important;

      opacity: 1;

      transition: .65s $in-out-circ .05s;
      transition-property: opacity, transform;

      &:hover {
        transform: translate3d(0, 0, 0) scale(1.1);

        transition-duration: .4s;
        transition-delay: 0;
      }
    }
  }
}

// textarea {
//   resize: none;
// }

// select {
//   padding-left: 0;
//   padding-right: 0;
// }

// option {
//   padding-block-start: 0;
//   padding-inline-start: 0;
//   padding-block-end: 0;
//   padding-inline-end: 0;
// }

input,
select {

  // Autofill colors hack
  &:-webkit-autofill {
    -webkit-text-fill-color: v(primary);
    background-color: transparent;
    box-shadow: 0 0 0px 100px transparent inset;
    transition: background-color 500000s ease-in-out 500000s;

    &::selection {
      -webkit-text-fill-color: v(accent);
    }
  }
}

input,
button {
  background: transparent;
  border: none;
  color: v(primary);

  font-weight: 700;

  &:focus {
    outline: none;
  }
}

button {
  padding: 0;

  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;

  color: v(primary);

  * { pointer-events: none; }
}

/* ABBREVIATIONS */

abbr[title] {
  @media (--phone) {
    border: none;
    text-decoration: none;
  }
}

/* ADDRESS */

address {
  font-style: normal;
}

/* LINKS */

a {
  color: v(primary);

  // transition: color .15s $in-out-quad;

  @media (--phone) {
    text-decoration: none;
  }

  // &:focus,
  // &:hover {
  //   color: v(accent);

  //   @include outline(v(accent), .1rem, dashed, .3rem);
  // }
}

/* TITLES */

h1,
h2,
h3,
h4,
h5,
h6 {
  @media (--phone) {
    margin: 0;
  }

  em {
    font-style: normal;
  }
}

/* SVG */

svg {
  pointer-events: none;

  * { transform-origin: 50% 50%; }
}

/* TABLES */

table {
  font-size: inherit;
  border-collapse: collapse;
}

th {
  text-align: left;
  font-weight: inherit;
}
