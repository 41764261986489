/*
 * FONT-SWAP
 *
 * Use:
 * @include font-swap;
 */

@mixin font-swap() {
  @if variable-exists('font-swap') != true or $font-swap != false {
    font-display: swap;
  }
}
