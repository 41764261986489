.top-bar {
  flex-shrink: 0;
  height: 7rem;
  padding: 0 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.6rem;

  box-shadow: 0 .2rem .6rem hsl(0 0% 0% / .1);
}

.top-bar__item {
  color: #a5a5a5;
  text-decoration: none;

  @include all-but(last) {
    margin-left: 2em;
  }
}

.top-bar__item--with-separator {
  position: relative;

  &::before {
    content: '';
    margin: 0 2em;

    display: inline-block;
    size: .2rem 3rem;

    vertical-align: middle;

    background: $gallery;
  }
}
