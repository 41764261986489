@font-face {
  font-family: 'Proximus';
  src:
    url('/fonts/proximus/proximus-light.woff2') format('woff2'),
    url('/fonts/proximus/proximus-light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  @include font-swap;
}

@font-face {
  font-family: 'Proximus';
  src:
    url('/fonts/proximus/proximus-regular.woff2') format('woff2'),
    url('/fonts/proximus/proximus-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  @include font-swap;
}

@font-face {
  font-family: 'Proximus';
  src:
    url('/fonts/proximus/proximus-bold.woff2') format('woff2'),
    url('/fonts/proximus/proximus-bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  @include font-swap;
}

@font-face {
  font-family: 'Proximus';
  src:
    url('/fonts/proximus/proximus-extrabold.woff2') format('woff2'),
    url('/fonts/proximus/proximus-extrabold.woff') format('woff');
  font-style: normal;
  font-weight: 900;
  @include font-swap;
}
