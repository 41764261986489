$login-h: (
  'login-compact': 32em, // 512px
);

@include --h($login-h);

.login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-bar--login {
  align-self: stretch;

  box-shadow: 0 .2rem .6rem 0 hsla(0, 0%, 0%, 0.1);
}

.top-bar__logo {
  height: auto;
}

.login__form {
  flex-grow: 9;
  margin-bottom: 10rem;
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (--h-to-login-compact) {
    margin: 5rem 0 5rem;
  }

  @media (--tablet-10) {
    padding-left: 29rem;
  }
}

.login__title {
  margin: 0;

  font-weight: 800;
  font-size: 4.5rem;
  line-height: 1.2;
}

.login__subtitle {
  margin: 0;

  font-size: 1.6rem;

  color: $brown-grey;
}

.login__fieldset {
  width: 100%;
}

.login__field {
  margin-top: 4rem;

  display: block;
  position: relative;

  overflow: hidden;
}

.login__input {
  width: 100%;
  max-width: 30rem;
  height: 5rem;
  padding: 0 1rem;

  background: hsl(0, 0%, 96%);
  border-radius: .4rem;
  border: .2rem solid;
  color: hsl(0, 0%, 92%);

  transition: color .15s $in-out-quad;

  &:focus,
  &:hover,
  &:valid {
    color: $brown-grey;

    + .login__label {
      transform: translate3d(.1em, -1.2em, 0) scale(.8);
    }
  }
}

.login__label {
  position: absolute;
  inset: 50% 0 0 1rem;
  transform-origin: left;

  line-height: 0;

  pointer-events: none;

  color: $greyish-brown;
  opacity: .3;

  transition: transform .15s $in-out-quad;
}

.connect {
  margin-top: 4rem;
  padding: 1.6rem 4.2rem;

  font-size: 1.4rem;

  border: .2rem solid;
  border-radius: .5rem .5rem 1.4rem .5rem;
}
