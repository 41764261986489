.f500 {
  font-weight: 500;
}

.f700 {
  font-weight: 700;
}

.f900 {
  font-weight: 900;
}

.h1 {
  font-size: 3.2rem;
  font-weight: 800;
}

.h2 {
  font-size: 2rem;
  font-weight: 700;
}
